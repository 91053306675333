
/* Importing Bootstrap SCSS file. */
@import '~bootstrap/scss/bootstrap';
@import '~ngx-toastr/toastr-bs4-alert';



.progressbar {
    counter-reset: step;
}
.progressbar li {
    list-style-type: none;
    width: 32%;
    float: left;
    font-size: 12px;
    position: relative;
    text-align: center;
    text-transform: uppercase;
    color: #000;
}
.progressbar li:before {
    width: 30px;
    height: 30px;
    content: counter(step);
    counter-increment: step;
    line-height: 30px;
    border: 2px solid #7d7d7d;
    display: block;
    text-align: center;
    margin: 0 auto 10px auto;
    border-radius: 50%;
    background-color: white;
}
.progressbar li:after {
    width: 100%;
    height: 2px;
    content: '';
    position: absolute;
    background-color: #7d7d7d;
    top: 15px;
    left: -50%;
    z-index: -1;
}
.progressbar li:first-child:after {
    content: none;
}
.progressbar li.active {
    color: white;
    font-weight: 600;
    text-shadow: 1px 1px black;
}
.progressbar li.actived {
    color: white;
    font-weight: 600;
    text-shadow: 1px 1px black;
}
.progressbar li.active:before {
    border-color: #edcd1f;
    color: #1e2d3b;
    font-weight: 900;
    text-shadow: 0px 0px black;
}
.progressbar li.actived:before {
    border-color: #edcd1f;
    color: #1e2d3b;
    font-weight: 900;
    text-shadow: 0px 0px black;
}
// .progressbar li.active + li:before {
//     background-color: #edcd1f;
// }
.progressbar li.active + li:after {
    background-color: #7d7d7d;
}
.progressbar li.actived + li:after {
    background-color: #edcd1f;
}
.p-center{
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    margin-top: 50px;
}



.form-out{
    
    background-image: url('assets/registerdreamcover.jpg');
    min-height: 300px;
    height: 25vh;
}